import React, { useContext, useEffect, useState } from "react";
import { CheckboxProps, Dropdown, DropdownProps, Form, InputOnChangeData, Message, Segment } from "semantic-ui-react";
import { AppLabel, ILabelProps } from "./AppLabel";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import RadioButton from "./RadioInput";
import AppContext from "../../contexts/AppContext";
registerLocale("es", es);
setDefaultLocale("en");

/**
 * Dont update Formik until user leaves field
 */

export interface IOption {
    value: string;
    text: string;
}

interface IFieldProps extends ILabelProps {
    name: string;
    disabled?: boolean;
    value: any;
    onChanged: (val: any) => void;
    error: string;
    required?: boolean;
    tabIndex: number;
    locale?: string;
}

interface IDateFieldProps extends IFieldProps {
    noMin?: boolean
}

interface IOptionProps extends IFieldProps {
    options: IOption[];
    note?: string;
}

export function AppTextbox(props: IFieldProps) {
    const handleChange = (e: any, data: InputOnChangeData) => {
        props.onChanged(data.value);
    };

    return (
        <Form.Input
            aria-label={props.label}
            label={props.label}
            name={props.name}
            onChange={handleChange}
            value={props.value}
            disabled={props.disabled}
            error={props.error && { content: props.error }}
            required={props.required}
        />
    );
}

export function AppDate(props: IDateFieldProps) {
    const [minDate, setMinDate] = useState(moment("2020-12-01").toDate());
    const [maxDate] = useState(moment().toDate());

    const handleChange = (d: Date) => {
        props.onChanged(d);
    };

    useEffect(() => {
        if (props.noMin) {
            setMinDate(moment("1908-01-01").toDate())
        }
    }, [props.noMin])

    return (
        <Form.Field>
            <AppLabel
                label={props.label}
                subLabel={props.subLabel}
                subLabelLink={props.subLabelLink}
                required={props.required}
                id={props.name + "-label"}
                className="app-label-small"
            />
            <Message error visible={props.error ? true : false}>
                {props.error}
            </Message>
            <DatePicker
                locale={props.locale || "en"}
                aria-label={props.label}
                ariaLabelledBy={props.name + "-label"}
                selected={props.value}
                onChange={handleChange}
                minDate={minDate}
                maxDate={maxDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                // strictParsing
                dateFormat="M/d/yyyy"
                disabled={props.disabled}
            />
        </Form.Field>
    );
}

export function AppDropdown(props: IOptionProps) {
    const handleChange = (e: any, data: DropdownProps) => {
        props.onChanged(data.value);
    };

    return (
        <Form.Field>
            <AppLabel
                label={props.label}
                subLabel={props.subLabel}
                subLabelLink={props.subLabelLink}
                required={props.required}
                className="app-label-small"
            />
            <Message error visible={props.error ? true : false}>
                {props.error}
            </Message>
            {props.note && (
                <Message
                    icon="info circle"
                    size={"tiny"}
                    content={<ReactMarkdown linkTarget="_blank">{props.note}</ReactMarkdown>}
                />
            )}
            <Dropdown
                compact={true}
                aria-label={props.label}
                search
                selection
                value={props.value}
                options={props.options}
                onChange={handleChange}
                disabled={props.disabled}
            />
        </Form.Field>
    );
}

export function AppRadioGroup(props: IOptionProps) {
    const handleClick = (newVal: string) => {
        if (props.disabled) {
            return;
        }
        props.onChanged(props.value === newVal ? "" : newVal);
    };

    const renderOption = (option: IOption) => {
        const key = `${props.name}-${option.value}`;
        return (
            <RadioButton
                aria-label={option.text}
                key={key}
                label={option.text}
                name={props.name}
                value={props.value}
                checked={props.value === option.value}
                onClick={() => handleClick(option.value as string)}
                disabled={props.disabled}
            />
        );
    };
    return (
        <Form.Group grouped>
            <AppLabel
                label={props.label}
                subLabel={props.subLabel}
                subLabelLink={props.subLabelLink}
                required={props.required}
                className="app-label-small"
            />
            <Message error visible={props.error ? true : false}>
                {props.error}
            </Message>
            {props.note && (
                <Message
                    icon="info circle"
                    size={"tiny"}
                    content={<ReactMarkdown linkTarget="_blank">{props.note}</ReactMarkdown>}
                />
            )}
            {props.options.map(renderOption)}
        </Form.Group>
    );
}
export function AppCheckboxGroup(props: IOptionProps) {
    const handleClick = (e: any, data: CheckboxProps) => {
        if (props.disabled) {
            return;
        }
        const option = data.value.toString();

        if (data.checked) {
            if (!props.value.includes(option)) {
                props.onChanged([...props.value, option]);
            }
        } else {
            const index = props.value.indexOf(option);
            if (index > -1) {
                const copy = [...props.value];
                copy.splice(index, 1);
                props.onChanged([...copy]);
            }
        }
    };
    const renderOption = (option: IOption) => {
        const key = `${props.name}-${option.value}`;
        return (
            <Form.Checkbox
                aria-label={option.text}
                key={key}
                label={<label dangerouslySetInnerHTML={{ __html: option.text }} />}
                name={props.name}
                value={option.value as string}
                checked={props.value.includes(option.value as string)}
                onClick={handleClick}
                disabled={props.disabled}
                tabIndex={props.tabIndex}
            />
        );
    };

    return (
        <Form.Field>
            <AppLabel
                label={props.label}
                subLabel={props.subLabel}
                subLabelLink={props.subLabelLink}
                required={props.required}
            />
            <Message error visible={props.error ? true : false}>
                {props.error}
            </Message>
            <Segment>{props.options.map(renderOption)}</Segment>
        </Form.Field>
    );
}

export function AppCheckBoxRadio(props: IOptionProps) {
    const minorOptions = props.options.slice(3).map((item) => item.value);
    const handleClickCheckBox = (e: any, data: CheckboxProps) => {
        if (props.disabled) {
            return;
        }
        const option = data.value.toString();

        if (data.checked) {
            if (!props.value.includes(option)) {
                let spredValue = [...props.value]

                if (option === props.options[2].value as string || option === props.options[3].value as string) {
                    spredValue = []
                }

                props.onChanged([...spredValue, option]);
            }
        } else {
            const index = props.value.indexOf(option);
            if (index > -1) {
                const copy = [...props.value];
                copy.splice(index, 1);
                props.onChanged([...copy]);
            }
        }
    };
    const handleClickRadioButton = (newVal: string) => {
        if (props.disabled) {
            return;
        }
        var ICOptions = props.value.filter((value: string) => !minorOptions.includes(value));
        if (!props.value.includes(newVal)) {
            props.onChanged([...ICOptions, newVal]);
        }
    };

    const renderMinorOption = (option: IOption) => {
        return (
            <RadioButton
                aria-label={option.text}
                key={`${props.name}-${option.value}`}
                label={option.text}
                name={props.name}
                value={option.value as string}
                checked={props.value.includes(option.value as string)}
                onClick={() => handleClickRadioButton(option.value as string)}
                disabled={props.disabled}
            />
        );
    };
    return (
        <Form.Field>
            <AppLabel
                label={props.label}
                subLabel={props.subLabel}
                subLabelLink={props.subLabelLink}
                required={props.required}
            />
            <Message error visible={props.error ? true : false}>
                {props.error}
            </Message>
            <Segment>
                <Form.Checkbox
                    aria-label={props.options[0].text}
                    key={`${props.name}-${props.options[0].value}`}
                    label={<label dangerouslySetInnerHTML={{ __html: props.options[0].text }} />}
                    name={props.name}
                    value={props.options[0].value as string}
                    checked={props.value.includes(props.options[0].value as string)}
                    onClick={handleClickCheckBox}
                    disabled={
                        props.disabled ||
                        props.value.includes(props.options[3].value as string) ||
                        props.value.includes(props.options[2].value as string) ||
                        props.value.includes(props.options[4].value as string)
                    }
                />
                {props.value.includes(props.options[0].value as string) && (
                    <Segment>
                        <Form.Field>
                            <Form.Group grouped>{[...props.options].splice(5).map(renderMinorOption)}</Form.Group>
                        </Form.Field>
                    </Segment>
                )}
                <Form.Checkbox
                    aria-label={props.options[1].text}
                    key={`${props.name}-${props.options[1].value}`}
                    label={<label dangerouslySetInnerHTML={{ __html: props.options[1].text }} />}
                    name={props.name}
                    value={props.options[1].value as string}
                    checked={props.value.includes(props.options[1].value as string)}
                    onClick={handleClickCheckBox}
                    disabled={
                        props.disabled ||
                        props.value.includes(props.options[3].value as string) ||
                        props.value.includes(props.options[2].value as string) ||
                        props.value.includes(props.options[4].value as string)
                    }
                />
                <Form.Checkbox
                    aria-label={props.options[2].text}
                    key={`${props.name}-${props.options[2].value}`}
                    label={<label dangerouslySetInnerHTML={{ __html: props.options[2].text }} />}
                    name={props.name}
                    value={props.options[2].value as string}
                    checked={props.value.includes(props.options[2].value as string)}
                    onClick={handleClickCheckBox}
                    // disabled={props.disabled || props.value.includes(props.options[3].value as string)}
                    disabled={
                        props.disabled ||
                        props.value.includes(props.options[0].value as string) ||
                        props.value.includes(props.options[1].value as string) ||
                        props.value.includes(props.options[3].value as string) ||
                        props.value.includes(props.options[4].value as string)
                    }
                />
                <Form.Checkbox
                    aria-label={props.options[3].text}
                    key={`${props.name}-${props.options[3].value}`}
                    label={<label dangerouslySetInnerHTML={{ __html: props.options[3].text }} />}
                    name={props.name}
                    value={props.options[3].value as string}
                    checked={props.value.includes(props.options[3].value as string)}
                    onClick={handleClickCheckBox}
                    // disabled={props.disabled || props.value.includes(props.options[2].value as string)}
                    disabled={
                        props.disabled ||
                        props.value.includes(props.options[0].value as string) ||
                        props.value.includes(props.options[1].value as string) ||
                        props.value.includes(props.options[2].value as string) ||
                        props.value.includes(props.options[4].value as string)
                    }
                />
                <Form.Checkbox
                    aria-label={props.options[4].text}
                    key={`${props.name}-${props.options[4].value}`}
                    label={<label dangerouslySetInnerHTML={{ __html: props.options[4].text }} />}
                    name={props.name}
                    value={props.options[4].value as string}
                    checked={props.value.includes(props.options[4].value as string)}
                    onClick={handleClickCheckBox}
                    // disabled={props.disabled || props.value.includes(props.options[2].value as string)}
                    disabled={
                        props.disabled ||
                        props.value.includes(props.options[0].value as string) ||
                        props.value.includes(props.options[1].value as string) ||
                        props.value.includes(props.options[2].value as string) ||
                        props.value.includes(props.options[3].value as string)
                    }
                />
            </Segment>
        </Form.Field>
    );
}

interface WhichRoutineDoseRadioProps extends IOptionProps {
    vaccine: string
}

export function WhichRoutineDoseRadio(props: WhichRoutineDoseRadioProps) {
    const ctx = useContext(AppContext);
    const handleClick = (newVal: string) => {
        if (props.disabled) {
            return;
        }
        props.onChanged(props.value === newVal ? "" : newVal);
    };

    const renderOption = (option: IOption) => {
        const key = `${props.name}-${option.value}`;
        return (
            <RadioButton
                aria-label={option.text}
                key={key}
                label={option.text}
                name={props.name}
                value={props.value}
                checked={props.value === option.value}
                onClick={() => handleClick(option.value as string)}
                disabled={props.disabled}
            />
        );
    };

    let maxDose = 5;
    let hasBooster = false;

    switch (props.vaccine) {
        case 'Polio vaccine':
            maxDose = 4;
            hasBooster = true;
            break;
        case 'Measles, Mumps, and Rubella (MMR) vaccine':
            maxDose = 3;
            break;
        case 'Varicella (Chickenpox) vaccine':
            maxDose = 2;
            break;
        case 'Diphtheria, Tetanus & Acellular Pertussis (DTaP/Tdap) vaccine':
            maxDose = 5;
            hasBooster = true;
            break;
        case 'Hepatitis A vaccine':
            maxDose = 3;
            hasBooster = true;
            break;
        case 'Hepatitis B vaccine':
            maxDose = 4;
            break;
        case 'Rotavirus vaccine':
            maxDose = 3;
            break;
        case 'Dengue vaccine':
            maxDose = 3;
            break;
        case 'Human Papillomavirus (HPV) vaccine':
            maxDose = 3;
            break;
        case 'Meningococcal ACWY vaccine':
            maxDose = 3;
            hasBooster = true;
            break;
        case 'Meningococcal B vaccine':
            maxDose = 3;
            hasBooster = true;
            break;
        case 'Pneumococcal Conjugate vaccine':
            maxDose = 4;
            break;
        case 'Pneumococcal Polysaccharide vaccine':
            maxDose = 3;
            hasBooster = false;
            break;
        case 'Recombinant Zoster (Shingles) vaccine':
            maxDose = 2;
            hasBooster = false;
            break;
    }

    const humanReadableMap: {en: string, es: string}[] = [
        {
            en: "1st dose",
            es: "1.a dosis"
        },
        {
            en: "2nd dose",
            es: "2.a dosis"
        },
        {
            en: "3rd dose",
            es: "3.a dosis"
        },
        {
            en: "4th dose",
            es: "4.a dosis"
        },
        {
            en: "5th dose",
            es: "5.a dosis"
        }
    ]

    const options: IOption[] = new Array(maxDose).fill(null).map((n, i) => ({
        text: humanReadableMap[i][ctx.lang] ? humanReadableMap[i][ctx.lang] : `${i + 1}`,
        value: String(i + 1)
    }))

    if (hasBooster) {
        options.push({
            text: ctx.lang === 'es' ? 'Dosis de refuerzo' :  'Booster dose',
            value: "Booster"
        })
    }

    return (
        <Form.Group grouped>
            <AppLabel
                label={props.label}
                subLabel={props.subLabel}
                subLabelLink={props.subLabelLink}
                required={props.required}
                className="app-label-small"
            />
            <Message error visible={props.error ? true : false}>
                {props.error}
            </Message>
            {props.note && (
                <Message
                    icon="info circle"
                    size={"tiny"}
                    content={<ReactMarkdown linkTarget="_blank">{props.note}</ReactMarkdown>}
                />
            )}
            {options.map(renderOption)}
        </Form.Group>
    );
}
// {props.options.map(renderOption)}

import { Button, Grid, Header, Image } from "semantic-ui-react";
import { UrlHelper } from "../shared/url.helper";
import { useHistory } from "react-router-dom";
import AppContext from "../contexts/AppContext";
import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";

const unbrand = () => UrlHelper.upsertParams({ jurisdiction: null });

export function LandingPage() {
    const history = useHistory();

    const ctx = useContext(AppContext);
    const jurisdiction = UrlHelper.get("jurisdiction");

    const lang = ctx.lang;

    let qparam = "?language=en_US";
    if (lang === "es") {
        qparam = "es";
    }

    const noApptURL = `${ctx.config.manageApptsUrl}${qparam}&redirect=registerRecipient`;

    const loginClicked = () => {
        window.location.href = `${ctx.config.loginUrl}${qparam}`;
    };

    const manageAppt = () => {
        let location = `${ctx.config.manageApptsUrl}${qparam}&redirect=manageAppointment`;

        // Only send clinicID if its present
        if (ctx.clinicID) {
            location = `${location}&clinicID=${ctx.clinicID}`;
        }

        // Only send locationID if its present
        if (ctx.locationID) {
            location = `${location}&locationID=${ctx.locationID}`;
        }

        // Only send vaccines if present
        if (ctx.availVaccines) {
            location = `${location}&vaccines=${ctx.availVaccines}`;
        }

        window.location.href = location;
    };

    return (
        <Grid container>
            <Grid.Row>
                <section>
                    <Header as="h1">{ctx.getJurisdictionLabel("welcome")}</Header>
                    <p>
                        <ReactMarkdown linkTarget="_blank">{ctx.getJurisdictionLabel("opening")}</ReactMarkdown>
                    </p>
                </section>
            </Grid.Row>
            <Grid.Row>
                <div className="cta-container">
                    {jurisdiction && (
                        <section className="login-cta">
                            <Header as="h2">{ctx.getLabel("loginCtaHeader")}</Header>
                            <Button
                                size="large"
                                style={{
                                    backgroundColor: "teal",
                                    color: "white",
                                }}
                                onClick={() => loginClicked()}
                            >
                                {ctx.getLabel("loginToVams")}
                            </Button>
                        </section>
                    )}
                    <section className="guest-cta">
                        <Header as="h2">{ctx.getLabel("guestCtaHeader")}</Header>
                        <Header as="h2">{ctx.getLabel("guestCtaSecondHeader")}</Header>
                        <Grid columns="three" stackable divided relaxed="very" verticalAlign="middle">
                            <Grid.Row
                                style={{
                                    paddingBottom: 20,
                                }}
                            >
                                <Grid.Column>
                                    <Image
                                        src="first_dose.png"
                                        height="125px"
                                        className="centered"
                                        alt="First Dose Graphic"
                                    />
                                    <Button
                                        style={{
                                            minHeight: "33%",
                                            marginBottom: "1.5em"
                                        }}
                                        size="large"
                                        fluid
                                        onClick={() => history.push(`/guest${unbrand()}`)}
                                        primary
                                        id="003"
                                    >
                                        {ctx.getLabel("scheduleVaccinationAppt")}
                                    </Button>
                                    <ReactMarkdown>{ctx.getLabel("scheduleVaccinationApptLabel")}</ReactMarkdown>
                                </Grid.Column>
                                <Grid.Column>
                                <Image
                                        src="covid-testing.png"
                                        height="125px"
                                        className="centered"
                                        alt="First Dose Graphic"
                                    />
                                    <Button
                                        style={{
                                            minHeight: "33%",
                                            marginBottom: "1.5em"
                                        }}
                                        size="large"
                                        fluid
                                        onClick={() => history.push(`/testLab`)}
                                        primary
                                        id="001"
                                    >
                                        {ctx.getLabel("scheduleTesting")}
                                    </Button>
                                    <ReactMarkdown>
                                        {ctx.getLabel("scheduleTestingNote")}
                                    </ReactMarkdown>
                                </Grid.Column>
                                <Grid.Column>
                                    <Image
                                        src="manage_appointments.png"
                                        height="125px"
                                        className="centered"
                                        alt="Manage Appointments Graphic"
                                    />
                                    <Button
                                        style={{
                                            height: "33%",
                                            marginBottom: "1.5em"
                                        }}
                                        size="large"
                                        fluid
                                        onClick={() => manageAppt()}
                                        primary
                                        id="004"
                                    >
                                        {ctx.getLabel("manageAppts")}
                                    </Button>
                                    <ReactMarkdown>{ctx.getLabel("manageApptsLabel")}</ReactMarkdown>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </section>
                </div>
                <section className="guest-cta-reg">
                    <p>
                        {ctx.getLabel("noappointment")} <a href={noApptURL}>{ctx.getLabel("urlnoappt")}</a>
                    </p>
                </section>
            </Grid.Row>
        </Grid >
    );
}

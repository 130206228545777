export interface IMultiLangLabel {
    en: string;
    es: string;
}
interface IMultiLangOption extends IMultiLangLabel {
    value: string;
}
interface IQuestionText {
    label: string;
    subLabel?: string;
    subLabelLink?: string;
}

interface IQuestionDefinition {
    en: IQuestionText;
    es: IQuestionText;
    options: IMultiLangOption[];
}

export type LanguageId = "en" | "es";

export type QuestionId =
    | "jurisdiction"
    | "singleDoseConfirm"
    | "vaccineReceived"
    | "pediatricICQuestion"
    | "pediatricQuestionDoseType"
    | "doseType"
    | "vaccineDate"
    | "vaccineLatestDate"
    | "isRepresentative"
    | "vaccineName"
    | "vaccineICName"
    | "testLabGroupQuestion"
    | "questionIC"
    | "vaccineAge"
    | "apptType"
    | "pediatricVaccine"
    | "whichDose"
    | "boosterNumber"
    | "generalAppointmentReason"
    | "generalAppointmentText"
    | "dobQuestion"
    | "receivedMmr"
    | "atRiskMmr"
    | "mmrVaccine"
    | "mmrDose"
    | "receivedPolio"
    | "atRiskPolio"
    | "polioDose"
    | "polioVaccine"
    | "covidAge"
    | "atRiskGeneral"
    | "whichRoutineDose"
    | "mpoxDose"
    | "whichMpoxVaccine"
    | "coadminFlu"

interface IQuestions {
    jurisdiction: IQuestionDefinition;
    singleDoseConfirm: IQuestionDefinition;
    vaccineReceived: IQuestionDefinition;
    pediatricICQuestion: IQuestionDefinition;
    pediatricQuestionDoseType: IQuestionDefinition;
    doseType: IQuestionDefinition;
    vaccineDate: IQuestionDefinition;
    vaccineLatestDate: IQuestionDefinition;
    vaccineName: IQuestionDefinition;
    vaccineICName: IQuestionDefinition;
    isRepresentative: IQuestionDefinition;
    apptType: IQuestionDefinition;
    testLabGroupQuestion: IQuestionDefinition;
    questionIC: IQuestionDefinition;
    vaccineAge: IQuestionDefinition
    pediatricVaccine: IQuestionDefinition;
    whichDose: IQuestionDefinition;
    boosterNumber: IQuestionDefinition;
    generalAppointmentReason: IQuestionDefinition;
    generalAppointmentText: IQuestionDefinition;
    dobQuestion: IQuestionDefinition;
    receivedMmr: IQuestionDefinition;
    atRiskMmr: IQuestionDefinition;
    mmrDose: IQuestionDefinition;
    mmrVaccine: IQuestionDefinition;
    receivedPolio: IQuestionDefinition;
    atRiskPolio: IQuestionDefinition;
    polioDose: IQuestionDefinition;
    polioVaccine: IQuestionDefinition;
    covidAge: IQuestionDefinition;
    atRiskGeneral: IQuestionDefinition;
    whichRoutineDose: IQuestionDefinition;
    mpoxDose: IQuestionDefinition;
    whichMpoxVaccine: IQuestionDefinition;
    coadminFlu: IQuestionDefinition;
}

export const Questions: IQuestions = {
    jurisdiction: {
        en: {
            label: "Select the state",
            subLabel:
                "Note: If you do not see the state or jurisdiction your are looking for, it may not be using VAMS. [Click here to find out more](REPLACE_FIND_VACCINE_URL?language=en_US).",
        },
        es: {
            label: "Seleccione el estado",
            subLabel:
                "Nota: si no ve el estado o la jurisdicción que está buscando, quizás no estén usando el VAMS. [Haga clic aquí para obtener más información](REPLACE_FIND_VACCINE_URL?language=es).",
        },
        options: [],
    },
    singleDoseConfirm: {
        en: {
            label: "Is this correct?",
        },
        es: {
            label: "¿Es esto correcto?",
        },
        options: [
            {
                en: `Yes, the REPLACE_VACCINE_NAME was administered and the vaccination is complete.`,
                es: "Sí, la vacuna contra el REPLACE_VACCINE_NAME fue administrada y la vacunación está completa.",
                value: "yes",
            },
            {
                en: "No, a different vaccine was administered.",
                es: "No, se administró una vacuna diferente.",
                value: "no",
            },
        ],
    },
    vaccineReceived: {
        en: {
            label: "Have you ever received a dose of COVID-19 vaccine?",
            subLabel: "Note: This is in reference to vaccination, not to any COVID-19 testing you may have received.",
        },
        es: {
            label: "¿Ha recibido alguna vez una vacuna contra el COVID-19?",
            subLabel:
                "Nota: esto se refiere a la vacunación, no a las pruebas del COVID-19 que se le pueden haber hecho.",
        },
        options: [
            { value: "yes", en: "Yes", es: "Sí" },
            { value: "no", en: "No", es: "No" },
        ],
    },
    pediatricICQuestion: {
        en: {
            label: "Are you moderately to severely immunocompromised?",
            subLabel: "Note: Find out more about moderately to [severely immunocompromised population](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html)"
        },
        es: {
            label: "¿Está usted moderada a gravemente inmunodeprimido/a?",
            subLabel: "Nota: Las personas que hayan recibido la vacuna contra el COVID-19 y que estén moderada a [gravemente inmunodeprimidas](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html) pueden programar la dosis de refuerzo después de 3 meses (90 días), en lugar de 5 meses (150 días), desde que completaron la serie inicial de vacunas contra el COVID-19 Pfizer-BioNTech o Moderna. [Haga clic aquí para informarse más.](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html) "
        },
        options: [
            { value: "yes", en: "Yes", es: "Sí" },
            { value: "no", en: "No", es: "No" },
        ],
    },
    pediatricQuestionDoseType: {
        en: {
            label:
                "Are you trying to schedule a COVID-19 booster dose based on the updated federal guidance?",
            subLabel:
                "Note: In order to be considered fully vaccinated you must complete all required doses (I.e., 2 dose for Pfizer-BioNtech, Moderna & Novavax and 1 dose for Janssen (Johnson & Johnson) ",
        },
        es: {
            label:
                "¿Está tratando de programar una cita para recibir una dosis de refuerzo contra el COVID-19 según las recomendaciones federales actualizadas?",
            subLabel:
                "Tenga en cuenta: para considerarse totalmente vacunado, debe recibir todas las dosis requeridas (es decir, 2 dosis de la vacuna Pfizer-BioNTech, Moderna y Novavax y 1 dosis de la vacuna Janssen de Johnson & Johnson).",
        },
        options: [
            {
                value: "4",
                en:
                    "Yes, schedule for COVID-19 vaccine **booster dose** appointment. [Click here to find out more](https://www.cdc.gov/vaccines/covid-19/planning/children.html).",
                es:
                    "Sí, programar una cita para la dosis de refuerzo.",
            },
            {
                value: "3",
                en:
                    "Yes, schedule for COVID-19 vaccine **additional dose** appointment. [Click here to find out more](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html).",
                es:
                    "Sí, programe la cita para la **dosis adicional** de la vacuna contra el COVID-19. [Haga clic aquí para obtener más información](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html).",
            },
            {
                value: "no",
                en: "No, schedule for COVID-19 vaccine **first and second dose** appointment.",
                es: "No, programar una cita para la primera o segunda dosis.",
            },
        ],
    },
    doseType: {
        en: {
            label:
                "Are you fully vaccinated and trying to schedule a COVID-19 additional dose based on the updated Federal guidance?",
            subLabel:
                "Note: In order to be considered fully vaccinated the recipient must have completed [all required doses](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/stay-up-to-date.html#UTD)",
        },
        es: {
            label:
                "¿Está completamente vacunado y trata de programar una cita para la dosis adicional o de refuerzo de la vacuna contra el COVID-19 según las directrices federales actualizadas?",
            subLabel:
                "Tenga en cuenta: Para considerarse totalmente vacunada, la persona debe haber recibido todas las dosis requeridas (es decir, 2 dosis de la vacuna Pfizer-BioNTech, Moderna o Novavax, y 1 dosis de la vacuna Janssen de Johnson, Johnson).",
        },
        options: [
            {
                value: "4",
                en:
                    "Yes, schedule for COVID-19 vaccine **additional dose** appointment.",
                es:
                    "Sí, programe una cita para la **dosis adicional** de la vacuna contra el COVID-19.",
            },
            {
                value: "3",
                en:
                    "Yes, schedule for COVID-19 vaccine **third dose** appointment.",
                es:
                    "Sí, programe la cita para la **tercera dosis** de la vacuna contra el COVID-19",
            },
            {
                value: "no",
                en: "No, schedule for COVID-19 vaccine **first and second dose** appointment.",
                es: "No, programe la cita para la **primera o segunda dosis** de la vacuna contra el COVID-19.",
            },
        ],
    },
    boosterNumber: {
        en: {
            label:
                "Which COVID-19 Booster dose are you trying to schedule an appointment for?",
        },
        es: {
            label:
                "¿Para qué dosis de refuerzo de la vacuna contra el COVID-19 está tratando de programar una cita?",
        },
        options: [
            {
                value: "1",
                en: "**First** booster dose",
                es: "**Primera** dosis de refuerzo",
            },
            {
                value: "2",
                en: "**Second** booster dose",
                es: "**Segunda** dosis de refuerzo",
            },
            {
                value: "3",
                en: "**Third** booster dose",
                es: "**Tercera** dosis de refuerzo",
            },
            {
                value: "4",
                en: "**Fourth** booster dose",
                es: "**Cuarta** dosis de refuerzo",
            },
        ],
    },
    vaccineDate: {
        en: {
            label: "Approximately when did you receive your last vaccine?",
            subLabel: "Format: m/d/yyyy",
        },
        es: {
            label: "Aproximadamente, ¿cuándo recibió su última vacuna?",
            subLabel: "Formato: m/d/yyyy",
        },
        options: [],
    },
    vaccineLatestDate: {
        en: {
            label: "When did you receive the latest dose of COVID-19 vaccine?",
            subLabel: "Format: m/d/yyyy",
        },
        es: {
            label: "¿Cuándo recibió la última dosis de la vacuna contra el COVID-19?",
            subLabel: "Formato: m/d/yyyy",
        },
        options: [],
    },
    vaccineName: {
        en: {
            label: "Which vaccine did you receive?",
        },
        es: {
            label: "¿Cuál vacuna recibió?",
        },
        options: [],
    },
    isRepresentative: {
        en: {
            label: "Is this form being completed on the recipient's behalf by a representative or guardian?",
        },
        es: {
            label: "NEED SPANISH",
        },
        options: [
            { value: "yes", en: "Yes", es: "Sí" },
            { value: "no", en: "No", es: "No" },
        ],
    },
    apptType: {
        en: {
            label: "Would you like to pre-register a walk-in appointment or schedule a future appointment?",
        },
        es: {
            label: "¿Le gustaría preinscribirse para ir sin cita o programar una cita futura?",
        },
        options: [
            {
                value: "preregister",
                en: "Pre-register for a walk-in appointment",
                es: "Preinscribirse para ir sin cita",
            },
            { value: "future", en: "Schedule a future appointment", es: "Programar una cita futura" },
        ],
    },
    vaccineICName: {
        en: {
            label: "Select the vaccine(s) you want to schedule an appointment for:",
        },
        es: {
            label: "Seleccione las vacunas que quiere programar:",
        },
        options: [
            { value: "COVID", en: "COVID-19 vaccine", es: "Vacuna contra el COVID-19" },
            { value: "Influenza", en: "Influenza vaccine", es: "Vacuna contra la influenza" },
            { value: "Measles, Mumps, and Rubella (MMR) vaccine", en: "Measles, Mumps, and Rubella (MMR) vaccine", es: "Vacuna contra el sarampión, las paperas y la rubéola (MMR)" },
            { value: "Polio vaccine", en: "Polio vaccine", es: "Vacuna contra la poliomielitis" },
            { value: "general", en: "General appointment", es: "Cita general" },
            { value: "Dengue vaccine", en: "Dengue vaccine", es: "Vacuna contra el dengue" },
            { value: "Diphtheria, Tetanus & Acellular Pertussis (DTaP/Tdap) vaccine", en: "Diphtheria, Tetanus & Acellular Pertussis (DTaP/Tdap) vaccine", es: "Vacuna contra la difteria, el tétanos y vacuna acelular contra la tosferina (DTaP/Tdap)" },
            { value: "Hepatitis A vaccine", en: "Hepatitis A vaccine", es: "Vacuna contra la hepatitis A" },
            { value: "Hepatitis B vaccine", en: "Hepatitis B vaccine", es: "Vacuna contra la hepatitis B" },
            { value: "Human Papillomavirus (HPV) vaccine", en: "Human Papillomavirus (HPV) vaccine", es: "Vacuna contra el virus del papiloma humano" },
            { value: "Meningococcal ACWY vaccine", en: "Meningococcal ACWY vaccine", es: "Vacuna antimeningocócica A,C,W,Y" },
            { value: "Meningococcal B vaccine", en: "Meningococcal B vaccine", es: "Vacuna antimeningocócica B" },
            { value: "Pneumococcal Conjugate vaccine", en: "Pneumococcal Conjugate vaccine", es: "Vacuna antineumocócica conjugada" },
            { value: "Pneumococcal Polysaccharide vaccine", en: "Pneumococcal Polysaccharide vaccine", es: "Vacuna antineumocócica polisacárida" },
            { value: "Rotavirus vaccine", en: "Rotavirus vaccine", es: "Vacuna contra el rotavirus" },
            { value: "Varicella (Chickenpox) vaccine", en: "Varicella (Chickenpox) vaccine", es: "Vacuna contra la varicela" },
            { value: "Recombinant Zoster (Shingles) vaccine", en: "Recombinant Zoster (Shingles) vaccine", es: "Vacuna contra el herpes zóster" },
            { value: "Mpox vaccine", en: "Mpox vaccine", es: "Vacuna contra la viruela símica o del mono" },
        ],
    },
    covidAge: {
        en: {
            label: "Select the vaccine you want to schedule an appointment for:",
        },
        es: {
            label: "Seleccione las vacuna que quiere programar:",
        },
        options: [
            {
                value: "adult",
                en: "COVID-19 vaccine for adults (ages 12 and above)",
                es: "Vacuna contra el COVID-19 para adultos (de 12 años de edad o más)",
            },
            {
                value: "minor",
                en: "COVID-19 vaccine for children (ages 5-11)",
                es: "Vacuna contra el COVID-19 para niños (de 5 a 11 años de edad)",
            },
            {
                value: "toddler",
                en: "COVID-19 vaccine for children (ages 6 months to 4 years)",
                es: "Vacuna contra el COVID-19 para niños (de 6 meses a 4 años de edad)",
            },
        ]
    },
    pediatricVaccine: {
        en: {
            label: "Select the vaccine(s) you want to schedule an appointment for",
        },
        es: {
            label: "Select the vaccine(s) you want to schedule an appointment for",
        },
        options: [
            {
                value: "minor",
                en: "COVID-19 vaccine for children (ages 5-11)",
                es: "Vacuna contra el COVID-19 para niños (de 5 a 11 años de edad)",
            },
            {
                value: "toddler",
                en: "COVID-19 vaccine for children (ages 6 months to 4 years)",
                es: "Vacuna contra el COVID-19 para niños (de 6 meses a 4 años de edad)",
            },
        ],
    },
    testLabGroupQuestion: {
        en: {
            label: "Select the COVID-19 test you would like to receive:",
        },
        es: {
            label: "Seleccione la prueba de COVID-19 que quiera hacerse.",
        },

        options: [
            {
                value: "DLT",
                en: "Diagnostic Lab Test- PCR (Molecular-PCR SARS-CoV2)",
                es: "Prueba diagnóstica de laboratorio-PCR (Molecular-PCR SARS-CoV2)",
            },
            {
                value: "RDT",
                en: "Rapid Diagnostic Test (IDNow Rapid COVID-19 PCR)",
                es: "Prueba diagnóstica rápida (IDNow Rapid COVID-19 PCR)",
            },
            {
                value: "RAT",
                en: "Rapid Antigen Test (BinaxNOW Rapid COVID-19 Antigen)",
                es: "Prueba de antígenos rápida (BinaxNOW Rapid COVID-19 Antigen)",
            },
            { value: "SST", en: "COVID-19 Antibody (SST)", es: "Prueba de anticuerpos contra el COVID-19 (SST)" },
        ],
    },
    questionIC: {
        en: {
            label: "Are you moderately to severely immunocompromised?",
            subLabel: "Note: Find out more about moderately to [severely immunocompromised population](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html)"
        },
        es: {
            label: "¿Está usted moderada a gravemente inmunodeprimido/a?",
            subLabel: "Nota: Nota: [Haga clic aquí para informarse más](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/stay-up-to-date.html) sobre la población moderada a [gravemente inmunodeprimida](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html)"
        },
        options: [
            { value: "yes", en: "Yes", es: "Sí" },
            { value: "no", en: "No", es: "No" },
        ],
    },
    vaccineAge: {
        en: {
            label: "Select the vaccine you schedule an appointment for",
        },
        es: {
            label: "Select the vaccine you schedule an appointment for",
        },
        options: [
            {
                value: "adult",
                en: "COVID-19 vaccine for adults (ages 12 and above)",
                es: "COVID-19 vaccine for adults (ages 12 and above)"
            },
            {
                value: "minor",
                en: "COVID-19 vaccine for children (ages 5-11)",
                es: "Vacuna COVID-19 para niños (de 5 a 11 años)",
            },
        ],
    },
    whichDose: {
        en: {
            label: "Which COVID-19 vaccine dose would you like to schedule an appointment for?",
        },
        es: {
            label: "¿Para qué dosis de la vacuna contra el COVID-19 le gustaría programar una cita?"
        },
        options: [
            {
                value: "1",
                en: "1st dose",
                es: "1.a dosis",
            },
            {
                value: "2",
                en: "2nd dose",
                es: "2.a dosis",
            },
            {
                value: "3",
                en: "3rd dose",
                es: "3.a dosis",
            },
            {
                value: "4",
                en: "Additional dose",
                es: "Dosis adicional",
            },
        ],
    },
    generalAppointmentReason: {
        en: {
            label: "Appointment reason",
        },
        es: {
            label: "Motivo de la cita",
        },
        options: [
            {
                value: "Blood Test (Sample Collection)",
                en: "Blood Test (Sample Collection)",
                es: "Análisis de sangre (recolección de muestras)",
            },
            {
                value: "Flu Test",
                en: "Flu Test",
                es: "Prueba de la influenza",
            },
            {
                value: "Illnesses/Infections (Prescription)",
                en: "Illnesses/Infections (Prescription)",
                es: "Enfermedades/infecciones (receta)",
            },
            {
                value: "Injury Diagnosis",
                en: "Injury Diagnosis",
                es: "Diagnóstico de lesión",
            },
            {
                value: "Men's Health",
                en: "Men's Health",
                es: "Salud del hombre",
            },
            {
                value: "Physical",
                en: "Physical",
                es: "Examen físico",
            },
            {
                value: "Refill & Renewal",
                en: "Refill & Renewal",
                es: "Resurtido/renovación de receta",
            },
            {
                value: "Screening",
                en: "Screening",
                es: "Prueba de detección",
            },
            {
                value: "STD Test",
                en: "STD Test",
                es: "Prueba de ETS",
            },
            {
                value: "Strep Throat",
                en: "Strep Throat",
                es: "Infección de garganta por estreptococos",
            },
            {
                value: "Travel Health",
                en: "Travel Health",
                es: "Salud del viajero",
            },
            {
                value: "Women's Health",
                en: "Women's Health",
                es: "Salud de la mujer",
            },
            {
                value: "Yearly Health Check",
                en: "Yearly Health Check",
                es: "Chequeo médico anual",
            },
            {
                value: "Other",
                en: "Other",
                es: "Otro",
            },
        ]
    },
    generalAppointmentText: {
        en: {
            label: "Please briefly describe the reason for your appointment (20 charcaters)",
        },
        es: {
            label: "Describa brevemente la razón de su cita (20 caracteres)",
        },
        options: []
    },
    dobQuestion: {
        en: {
            label: "Please enter your date of birth",
        },
        es: {
            label: "Ingrese su fecha de nacimiento",
        },
        options: []
    },
    receivedMmr: {
        en: {
            label: "Have you received a dose of Measles, Mumps, & Rubella (MMR) vaccine?",
        },
        es: {
            label: "¿Ha recibido una dosis de la vacuna contra el sarampión, las paperas y la rubéola (MMR)?",
        },
        options: [
            { value: "yes", en: "Yes", es: "Sí" },
            { value: "no", en: "No", es: "No" },
        ],
    },
    atRiskMmr: {
        en: {
            label: "Is the recipient traveling internationally or is at increased risk of exposure?",
        },
        es: {
            label: "La persona que se va a vacunar, ¿va a viajar al exterior o tiene un mayor riesgo de exposición?",
        },
        options: [
            { value: "yes", en: "Yes", es: "Sí" },
            { value: "no", en: "No", es: "No" },
        ],
    },
    mmrDose: {
        en: {
            label: "Which MMR vaccine dose would you like to schedule a future appointment for?",
        },
        es: {
            label: "¿Para qué dosis de la vacuna MMR le gustaría programar una cita?",
        },
        options: [
            { value: "2", en: "2nd dose", es: "Segunda dosis" },
            { value: "3", en: "3rd dose", es: "Tercera dosis" },
        ],
    },
    mmrVaccine: {
        en: {
            label: "Which vaccine did you receive?",
        },
        es: {
            label: "¿Cuál vacuna recibió?",
        },
        options: [
            { value: "M-M-R II", en: "M-M-R II", es: "M-M-R II" },
            { value: "Priorix", en: "Priorix", es: "Priorix" },
            { value: "ProQuad", en: "ProQuad", es: "ProQuad" },
            {
                en: "I don't remember",
                es: "No me acuerdo",
                value: "dont-remember"
            }
        ],
    },
    receivedPolio: {
        en: {
            label: "Have you received a dose of Polio vaccine?"
        },
        es: {
            label: "¿Ha recibido una dosis de la vacuna contra la poliomielitis?"
        },
        options: [
            { value: "yes", en: "Yes", es: "Sí" },
            { value: "no", en: "No", es: "No" },
        ],
    },
    atRiskPolio: {
        en: {
            label: "Is the recipient traveling internationally or is at increased risk of exposure?"
        },
        es: {
            label: "La persona que se va a vacunar, ¿va a viajar al exterior o tiene un mayor riesgo de exposición?"
        },
        options: [
            { value: "yes", en: "Yes", es: "Sí" },
            { value: "no", en: "No", es: "No" },
        ],
    },
    polioDose: {
        en: {
            label: "Which Polio vaccine dose would you like to schedule a future appointment for?"
        },
        es: {
            label: "¿Para qué dosis de la vacuna contra la poliomielitis le gustaría programar una cita?"
        },
        options: [
            { value: "2", en: "2nd dose", es: "Segunda dosis" },
            { value: "3", en: "3rd dose", es: "Tercera dosis" },
            { value: "4", en: "4th dose", es: "Cuarta dosis" },
            { value: "5", en: "Booster dose", es: "Refuerzo dosis" },
        ],
    },
    polioVaccine: {
        en: {
            label: "Which vaccine did you receive?"
        },
        es: {
            label: "¿Cuál vacuna recibió?"
        },
        options: [
            { value: "Ipol", en: "Ipol", es: "Ipol" },
            { value: "Pediarix", en: "Pediarix", es: "Pediarix" },
            { value: "Pentacel", en: "Pentacel", es: "Pentacel" },
            { value: "Vaxelis", en: "Vaxelis", es: "Vaxelis" },
            { value: "Kinrix", en: "Kinrix", es: "Kinrix" },
            { value: "Quadracel", en: "Quadracel", es: "Quadracel" },
            {
                en: "I don't remember",
                es: "No me acuerdo",
                value: "dont-remember"
            }
        ]
    },
    atRiskGeneral: {
        en: {
            label: "Are you traveling, immunocompromised, or fall under any other special situation(s) to receive this vaccine?",
        },
        es: {
            label: "¿Está viajando, está inmunodeprimido o se encuentra en alguna otra situación especial para recibir esta vacuna?",
        },
        options: [
            { value: "yes", en: "Yes", es: "Sí" },
            { value: "no", en: "No", es: "No" },
        ],
    },
    whichRoutineDose: {
        en: {
            label: "Which vaccine dose would you like to schedule an appointment for?",
        },
        es: {
            label: "¿Para qué dosis de la vacuna le gustaría programar una cita?",
        },
        options: [],
    },
    mpoxDose: {
        en: {
            label: "Which Monkeypox vaccine dose would you like to schedule a future appointment for?"
        },
        es: {
            label: "Which Monkeypox vaccine dose would you like to schedule a future appointment for?"
        },
        options: [
            {
                value: "1",
                en: "1st dose",
                es: "1.a dosis",
            },
            {
                value: "2",
                en: "2nd dose",
                es: "2.a dosis",
            },
        ],
    },
    whichMpoxVaccine: {
        en: {
            label: "Which vaccine did you receive?",
        },
        es: {
            label: "¿Cuál vacuna recibió?",
        },
        options: [
            {
                value: "JYNNEOS",
                en: "JYNNEOS",
                es: "JYNNEOS",
            },
            {
                value: "ACAM2000",
                en: "ACAM2000",
                es: "ACAM2000",
            },
            {
                en: "I don't remember",
                es: "No me acuerdo",
                value: "dont-remember"
            }
        ],
    },
    coadminFlu: {
        en: {
            label: "Would you like to schedule an influenza vaccine with your appointment?",
        },
        es: {
            label: "¿Le gustaría programar una vacuna infleunza con su cita?",
        },
        options: [
            { value: "yes", en: "Yes", es: "Sí" },
            { value: "no", en: "No", es: "No" },
        ],
    },
};

export interface IOption {
    value: string;
    text: string;
}
export interface IQuestionData {
    label: string;
    subLabel: string;
    subLabelLink: string;
    options: IOption[];
}

export function getQuestionForLanguage(id: QuestionId, lang: LanguageId = "en"): IQuestionData {
    const d = Questions[id];
    const labels = d[lang];
    const options = d.options?.map((o) => ({ value: o.value, text: o[lang] }));
    return {
        label: labels.label,
        subLabel: labels.subLabel,
        subLabelLink: labels.subLabelLink,
        options: options,
    };
}

export function getQuestionOptionValues(id: QuestionId, lang: LanguageId = "en"): string[] {
    return getQuestionForLanguage(id, lang).options.map((x) => x.value);
}

/**
 * Defines translation labels
 */
export type LabelId =
    | string
    | "jurisdiction"
    | "footerSupportLabel"
    | "footerInfoLabel"
    | "loginCtaHeader"
    | "guestCtaSecondHeader"
    | "guestCtaHeader"
    | "singleDoseLabel"
    | "singleDoseSublabel"
    | "requiredFieldError"
    | "vaccineDateError"
    | "vaccineNameRequiredError"
    | "vaccineDateRequiredError"
    | "loginToVams"
    | "scheduleFirstAppt"
    | "scheduleFirstApptLabel"
    | "scheduleVaccinationAppt"
    | "scheduleVaccinationApptLabel"
    | "schedulePediatricVaccinationAppt"
    | "schedulePediatricVaccinationApptLabel"
    | "scheduleSecondAppt"
    | "scheduleSecondApptLabel"
    | "scheduleAdditionalAppt"
    | "scheduleAdditionalApptLabel"
    | "manageAppts"
    | "manageApptsLabel"
    | "noappointment"
    | "urlnoappt"
    | "guestWelcome"
    | "cancel"
    | "confirm"
    | "vaccineComplete"
    | "vaccineCompleteDescription"
    | "closeBrowser"
    | "next"
    | "clinicVaccineError"
    | "additionalDoseBoosterNote"
    | "guestQuestionNote"
    | "guestQuestionOne"
    | "guestQuestionTwo"
    | "guestQuestionThree"
    | "guestQuestionFour"
    | "boosterNumber"
    | "charCount"
    | "mmrRecommendations"
    | "mmrLaterTime"
    | "polioRecommendation"
    | "polioAtRiskWarning"
    | "requiredNote"
    | "bivalentUpdate"
    | "mpoxNote"
    | "monovalentNote"
    | "acamError"

interface ILabels {
    [x: string]: IMultiLangLabel;
    loginCtaHeader: IMultiLangLabel;
    guestCtaSecondHeader: IMultiLangLabel;
    footerSupportLabel: IMultiLangLabel;
    footerInfoLabel: IMultiLangLabel;
    guestCtaHeader: IMultiLangLabel;
    vaccineNameRequiredError: IMultiLangLabel;
    vaccineDateRequiredError: IMultiLangLabel;
    singleDoseLabel: IMultiLangLabel;
    singleDoseSublabel: IMultiLangLabel;
    requiredFieldError: IMultiLangLabel;
    vaccineDateError: IMultiLangLabel;
    jurisdiction: IMultiLangLabel;
    loginToVams: IMultiLangLabel;
    scheduleFirstAppt: IMultiLangLabel;
    scheduleFirstApptLabel: IMultiLangLabel;
    scheduleVaccinationAppt: IMultiLangLabel;
    scheduleVaccinationApptLabel: IMultiLangLabel;
    schedulePediatricVaccinationAppt: IMultiLangLabel;
    schedulePediatricVaccinationApptLabel: IMultiLangLabel;
    scheduleSecondAppt: IMultiLangLabel;
    scheduleSecondApptLabel: IMultiLangLabel;
    scheduleAdditionalAppt: IMultiLangLabel;
    scheduleAdditionalApptLabel: IMultiLangLabel;
    manageAppts: IMultiLangLabel;
    manageApptsLabel: IMultiLangLabel;
    noappointment: IMultiLangLabel;
    urlnoappt: IMultiLangLabel;
    guestWelcome: IMultiLangLabel;
    cancel: IMultiLangLabel;
    confirm: IMultiLangLabel;
    next: IMultiLangLabel;
    vaccineComplete: IMultiLangLabel;
    vaccineCompleteDescription: IMultiLangLabel;
    closeBrowser: IMultiLangLabel;
    clinicVaccineError: IMultiLangLabel;
    additionalDoseBoosterNote: IMultiLangLabel;
    guestQuestionNote: IMultiLangLabel;
    pedsBivalentWarning: IMultiLangLabel;
    vaccineICNameError: IMultiLangLabel;
    mmrLaterTime: IMultiLangLabel;
    mmrDateRecived: IMultiLangLabel;
    polioRecommendation: IMultiLangLabel;
    polioAtRiskWarning: IMultiLangLabel;
    polioDateRecived: IMultiLangLabel;
    childrenVaccines: IMultiLangLabel;
    childrenVaccines18: IMultiLangLabel;
    childrenVaccines19: IMultiLangLabel;
    requiredNote: IMultiLangLabel;
    bivalentUpdate: IMultiLangLabel;
    mpoxNote: IMultiLangLabel;
    monovalentNote: IMultiLangLabel;
    acamError: IMultiLangLabel;
}

const Labels: ILabels = {
    jurisdiction: {
        en: "Jurisdiction",
        es: "Jurisdicción",
    },
    footerSupportLabel: {
        en: "Have Questions?",
        es: "¿TIENE PREGUNTAS?",
    },
    footerInfoLabel: {
        en: "CDC INFORMATION",
        es: "INFORMACIÓN DE LOS CDC",
    },
    loginCtaHeader: {
        en: "If you have already created a username and password, click below to login.",
        es: "Si ya creó un nombre de usuario y una contraseña, haga clic abajo para ingresar.",
    },
    guestCtaHeader: {
        en: "If you have not created a username and password, select one of the following three options.",
        es: "Si no ha creado un nombre de usuario y una contraseña, seleccione una de las siguientes tres opciones.",
    },
    guestCtaSecondHeader: {
        en: "Please note that if your vaccinations have not been scheduled and administered through the VAMS system, we won't be able to provide you with a vaccination certification or vaccine verification QR code at this time. For any inquiries, please call +1-833-748-1979.",
        es: "Tenga en cuenta que si sus vacunas no han sido programadas y administradas a través del sistema VAMS, no podremos proporcionarle un certificado de vacunación o un código QR de verificación de vacunas en este momento. Para cualquier consulta, llame al +1-833-748-1979.",
    },
    singleDoseLabel: {
        en:
            "The REPLACE_VACCINE_NAME is a one-dose vaccine. If it has already been received, then the vaccination is complete.",
        es:
            "La vacuna contra el REPLACE_VACCINE_NAME es una vacuna de una sola dosis. Si ya se recibió, la vacunación está completa.",
    },
    singleDoseSublabel: {
        en: "Note: If the REPLACE_VACCINE_NAME was received, new appointments cannot be scheduled in VAMS.",
        es:
            "Nota: Si ya se recibió la vacuna contra el REPLACE_VACCINE_NAME, no se pueden programar nuevas citas en el VAMS.",
    },
    requiredFieldError: {
        en: "This is a required field",
        es: "Este es un campo obligatorio",
    },
    vaccineNameRequiredError: {
        en: "Vaccine name is a required field",
        es: "El nombre de la vacuna es un campo obligatorio",
    },
    vaccineDateRequiredError: {
        en: "Vaccination date is a required field",
        es: "La fecha de vacunación es un campo obligatorio",
    },
    vaccineDateError: {
        en:
            "If you have already received a COVID-19 vaccine, the Prior Vaccination date should be between Emergency Use Authorization Approval date (REPLACE_DATE for REPLACE_VACCINE_NAME) and yesterday or today (assuming that you received your vaccination dose today). If you have NOT received a dose of COVID-19 Vaccine, please select 'No' in the question above.",
        es:
            "Si ya recibió una vacuna contra el COVID-19, la fecha de vacunación anterior debe ser entre la fecha de la Autorización de Uso de Emergencia (12/14/20 para Pfizer; 12/21/20 para Moderna) y ayer u hoy (suponiendo que recibió su dosis de la vacuna hoy). Si NO ha recibido una dosis de la vacuna contra el COVID-19, seleccione 'No' para responder la pregunta anterior.",
    },
    loginToVams: {
        en: "VAMS recipient login",
        es: "Ingreso al VAMS de la persona que recibe la vacuna",
    },
    scheduleFirstAppt: {
        en: "Schedule your 1st dose",
        es: "Programe su primera dosis",
    },
    scheduleFirstApptLabel: {
        en: "Schedule your **first appointment** by finding a clinic and a time slot that works for you.",
        es: "Encuentre un centro médico y un horario conveniente para programar su primera cita.",
    },
    scheduleSecondAppt: {
        en: "Schedule your 2nd dose",
        es: "Programe su segunda dosis",
    },
    scheduleSecondApptLabel: {
        en: "Schedule your **second appointment** by finding a clinic and a time slot that works for you.",
        es: "Encuentre un centro médico y un horario conveniente para programar su segunda cita.",
    },
    scheduleAdditionalAppt: {
        en: "Schedule your COVID-19 vaccine additional or booster dose",
        es: "Programe su dosis adicional o de refuerzo de la vacuna contra el COVID-19",
    },
    scheduleAdditionalApptLabel: {
        en:
            "Schedule your **additional dose** or **booster dose** appointment for COVID-19 vaccine by finding a clinic and a time slot that works for you.",
        es:
            "Programe la cita para su dosis adicional o de refuerzo de la vacuna contra el COVID-19 al encontrar un centro médico y un horario conveniente para usted.",
    },
    manageAppts: {
        en: "Manage Appointments",
        es: "Manejar citas",
    },
    manageApptsLabel: {
        en: "Manage a current appointment, appointment communication from VAMS or  update your account information.",
        es: "Maneje una cita actual, notificaciones sobre sus citas de VAMS o actualice su información.",
    },
    guestWelcome: {
        en: "Proceed as Guest",
        es: "Continuar como huésped",
    },
    next: {
        en: "Next",
        es: "Siguiente",
    },
    cancel: {
        en: "Cancel",
        es: "Cancelar",
    },
    confirm: {
        en: "Confirm",
        es: "Confirmar",
    },
    vaccineComplete: {
        en: "Your vaccination is complete.",
        es: "Su vacunación está completa.",
    },
    vaccineCompleteDescription: {
        en:
            "You received the REPLACE_VACCINE_NAME. Because this is a one-dose vaccine, you have completed your vaccination and are not eligible to schedule another appointment.",
        es:
            "Usted recibió la vacuna contra el REPLACE_VACCINE_NAME. Como es una vacuna de una sola dosis, usted ha completado su vacunación y no es elegible para programar otra cita.",
    },
    closeBrowser: {
        en: "You can now close your browser.",
        es: "Ya puede cerrar su navegador.",
    },
    noappointment: {
        en: "Not ready to schedule an appointment?",
        es: "¿No está listo para programar una cita?",
    },
    urlnoappt: {
        en: "Enter your information to pre-register.",
        es: "Ingrese su información para preinscribirse.",
    },
    clinicVaccineError: {
        en:
            "This clinic does not carry the vaccine you need for your second dose. Search for another clinic location to schedule a vaccine appointment.",
        es:
            "Este centro médico no dispone de la vacuna que usted necesita para la segunda dosis. Busque otro lugar para programar una cita de vacunación.",
    },
    scheduleVaccinationAppt: {
        en: "Schedule your appointment",
        es: "Programar su cita",
    },
    scheduleVaccinationApptLabel: {
        en: "Schedule your vaccine and/or general appointment by finding a clinic and a time slot that works for you.",
        es: "Programe su cita para vacunarse o una cita general encontrando un centro médico y un horario conveniente para usted.",
    },
    schedulePediatricVaccinationAppt: {
        en: "Schedule a pediatric COVID-19 vaccine appointment",
        es: "Programe una cita para la vacuna pediátrica contra el COVID-19",
    },
    schedulePediatricVaccinationApptLabel: {
        en:
            "Schedule a **pediatric** COVID-19 vaccine appointment for children **6 months to 11 years** old by finding a clinic and a timeslot that works for you",
        es:
            "Programe una cita para la vacuna pediátrica contra el COVID-19, para niños de 6 meses a 11 años. Encuentre un centro de vacunación y un horario que sean convenientes para usted.",
    },
    pediatricDoseNote: {
        en:
            "According to CDC recommendations, Children 6 months to 4 years old are able to get the Pediatric Pfizer-BioNTech COVID-19 vaccine. *Please refer to the CDC guidelines prior to scheduling an appointment.*",
        es:
            "Los niños de 6 meses a 4 años pueden recibir la vacuna pediátrica contra el COVID-19 Pfizer-BioNTech. *Consulte las directrices de los CDC antes de programar una cita.*",
    },
    pediatricDescOne: {
        en:
            "Children 6 months to 4 years old are able to get the Pediatric Pfizer-BioNTech COVID-19 vaccine. [Click here to find out more](https://www.cdc.gov/vaccines/covid-19/planning/children.html).",
        es:
            "Los niños de 5 a 11 años de edad pueden recibir la vacuna pediátrica contra el COVID-19 Pfizer-BioNTech. [Haga clic aquí para obtener más información](https://www.cdc.gov/vaccines/covid-19/planning/children.html).",
    },
    pediatricDescTwo: {
        en:
            "Representative/guardian information is required for scheduling a Pediatric COVID-19 vaccination appointment.",
        es:
            "Se requiere la información de un representante o tutor legal para programar la cita para la vacuna pediátrica contra el COVID-19.",
    },
    pediatricDescThree: {
        en:
            "COVID-19 Vaccine additional doses are available for moderately to severely immunocompromised populations who completed their initial series at least 28 days ago. [Click here to find out more](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html).",
        es:
            "Las dosis adicionales de la vacuna contra el COVID-19 están disponibles para las poblaciones moderada a gravemente inmunodeprimidas que completaron la serie inicial hace al menos 28 días. [Haga clic aquí para obtener más información](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html).",
    },
    pediatricDescFour: {
        en: "Children 5-11 years old are able to get the Pediatric Pfizer-BioNTech COVID-19 booster dose at least five months after completion of primary series. [Click here to find out more](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html)",
        es: "Los niños de 5 a 11 años de edad pueden recibir la dosis de refuerzo de la vacuna pediátrica contra el COVID-19 Pfizer-BioNTech al menos cinco meses después de completar la serie primaria. [Haga clic aquí para informarse más](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html)"
    },
    guestQuestionNote: {
        en:
            "According to CDC recommendations, only the Pfizer-BioNTech and Moderna vaccines are available as an [additional dose](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html), and the Pfizer-BioNTech, Moderna, and Janssen (Johnson & Johnson) vaccine(s) as a [booster](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html). Pfizer-BioNTech COVID-19 vaccine and Moderna COVID-19 vaccine are approved for children. *Please refer to the CDC guidelines prior to scheduling an appointment.*",
        es:
            "De acuerdo con las recomendaciones de los CDC, solamente las vacunas Pfizer y Moderna están disponibles para la [dosis adicional](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html), y las vacunas Pfizer, Moderna y Jansen (Johnson & Johnson) para la dosis de [refuerzo](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html). Las vacunas contra el COVID-19 Pfizer-BioNTech y Moderna están aprobadas para niños. Consulte las directrices de los CDC antes de programar una cita.",
    },
    guestQuestionOne: {
        en:
            "According to CDC recommendations, Pfizer-BioNTech, Moderna, Janssen/J&J and Novavax COVID-19 vaccines are approved for [adults and adolescents](https://www.cdc.gov/vaccines/covid-19/info-by-product/index.html).",
        es:
            "De acuerdo con las recomendaciones de los CDC, las vacunas contra el COVID-19 Pfizer-BioNTech, Moderna, Janssen de Johnson & Johnson y Novavax están aprobadas para los [adultos y adolescentes](https://espanol.cdc.gov/vaccines/covid-19/info-by-product/index.html).",
    },
    guestQuestionTwo: {
        en:
            "According to CDC recommendations, Pfizer-BioNTech COVID-19 vaccine and Moderna COVID-19 vaccine are approved for [children](https://www.cdc.gov/vaccines/covid-19/planning/children.html).",
        es:
            "De acuerdo con las recomendaciones de los CDC, las vacunas contra el COVID-19 Pfizer-BioNTech y Moderna están aprobadas para niños. [Haga clic aquí para obtener más información](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/children-teens.html).",
    },
    guestQuestionThree: {
        en:
            "Representative/guardian information is required for scheduling a Pediatric COVID-19 vaccination appointment.",
        es:
            "Se requiere la información de un representante o tutor legal para programar la cita para la vacuna pediátrica contra el COVID-19.",
    },
    guestQuestionFour: {
        en:
            "Representative/guardian information is required for scheduling a Pediatric COVID-19 vaccination appointment.",
        es:
            "Se requiere la información de un representante o tutor legal para programar la cita para la vacuna pediátrica contra el COVID-19.",
    },
    guestMmrNote: {
        en:
            "CDC recommends all children get two doses of [MMR (measles-mumps-rubella) vaccine](https://www.cdc.gov/vaccines/vpd/mmr/public/index.html), starting with the first dose at 12 through 15 months of age, and the second dose at 4 through 6 years of age.",
        es:
            "Los CDC recomiendan que todos los niños reciban dos dosis de la vacuna [MMR (contra el sarampión, las paperas y la rubéola)](https://www.cdc.gov/vaccines/vpd/mmr/public/index.html), la primera entre los 12 y 15 meses de edad y la segunda entre los 4 y 6 años de edad.",
    },
    additionalDoseBoosterNote: {
        en:
            "According to CDC recommendations, only the Pfizer and Moderna vaccines are available as an [additional dose](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html), and Pfizer, Moderna, and Janssen (Johnson & Johnson) vaccines as a [booster](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html). Children 5-11 years old are able to get the Pediatric Pfizer-BioNTech COVID-19 vaccine. *Please refer to the CDC guidelines prior to scheduling an appointment*.",
        es:
            "De acuerdo con las recomendaciones de los CDC, las vacunas contra el COVID-19 Pfizer-BioNTech y Moderna están aprobadas para niños. [Haga clic aquí para obtener más información](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/children-teens.html).",
    },
    additionalDesc: {
        en:
            "COVID-19 Vaccine additional doses are available for moderately to severely immunocompromised populations who completed their initial series at least 28 days ago. [Click here to find out more](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html).",
        es:
            "Las dosis adicionales de la vacuna contra el COVID-19 están disponibles para las poblaciones moderada a gravemente inmunodeprimidas que completaron la serie inicial hace al menos 28 días. [Haga clic aquí para obtener más información](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/recommendations/immuno.html).",
    },
    boosterDesc: {
        en:
            "COVID-19 vaccine booster shots are available for recipients who completed their initial series at least 2 months (60 days) earlier for Pfizer-BioNTech, Moderna, Janssen or Novavax COVID-19 vaccines. [Click here to find out more](https://www.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html).",
        es:
            "las dosis de refuerzo contra el COVID-19 están disponibles para quienes completaron la serie inicial de vacunas contra el COVID-19 hace al menos 5 meses (150 días), si recibieron la vacuna Pfizer-BioNTech, hace 5 meses (150 días), si recibieron la vacuna Moderna, y hace 2 meses (60 días), si recibieron la vacuna Janssen (Johnson & Johnson). [Haga clic aquí para obtener más información](https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/booster-shot.html).",
    },
    secondBoosterDesc: {
        en: "COVID-19 vaccine second booster shots are available for [certain individuals](https://www.cdc.gov/media/releases/2022/s0328-covid-19-boosters.html) who completed their initial series at least 4 months (120 days) earlier for all COVID-19 vaccines. [Click here to find out more](https://www.cdc.gov/vaccines/covid-19/clinical-considerations/interim-considerations-us.html#covid-vaccines).",
        es: "Están disponibles las segundas dosis de refuerzo de todas las vacunas contra el COVID-19 para [ciertas personas](https://www.cdc.gov/media/releases/2022/s0328-covid-19-boosters.html) que completaron su serie inicial hace al menos 4 meses (120 días). [Haga clic aquí para obtener más información](https://www.cdc.gov/vaccines/covid-19/clinical-considerations/interim-considerations-us.html#covid-vaccines)."
    },
    attestation: {
        en: "By checking this box, I confirm the above information is accurate.",
        es: "Al marcar esta casilla, confirmo que la información anterior es exacta.",
    },
    attestationAdditionalDose: {
        en:
            " By checking this box, I confirm that the above information is accurate. I attest that I meet the eligibility criteria to receive COVID-19 additional dose and that I am fully vaccinated.",
        es:
            " Al marcar esta casilla, confirmo que la información anterior es correcta. Confirmo que cumplo con los criterios de elegibilidad para recibir una dosis adicional de la vacuna contra el COVID-19 y que estoy completamente vacunado.",
    },
    attestationBoosterlDose: {
        en:
            "By checking this box, I confirm that the above information is accurate. I attest that I meet the eligibility criteria to receive an additional dose and I am fully vaccinated.",
        es:
            "Al marcar esta casilla, confirmo que la información anterior es correcta. Confirmo que cumplo con los criterios de elegibilidad para recibir la dosis adicional y que estoy completamente vacunado.",
    },
    boosterDateWarning: {
        en:
            "Bivalent Booster is authorized for administration at least two months following completion of primary series or most recent booster vaccination.",
        es:
            "Tenga en cuenta: El refuerzo bivalente está autorizado para su administración al menos dos meses después de haber completado la serie primaria o recibido el refuerzo más reciente.",
    },
    boosterDateWarningNovavax: {
        en: "Novavax COVID-19 Vaccine is authorized to be administered as a first booster dose at least 6 months after completion of primary vaccination for individuals 18 years of age and older.",
        es: "La vacuna contra el COVID-19 Novavax está autorizada para administrarse como la primera dosis de refuerzo al menos 6 meses después de completarse la serie primaria de vacunación para las personas de 18 años o más."
    },
    pediatricBoosterDateWarning: {
        en:
            "COVID-19 vaccine booster shots for children (ages 5-11) are available only for the Pfizer-BioNTech vaccine recipients who completed their initial series at least 5 months ago.",
        es:
            "Las dosis de refuerzo contra el COVID-19 para los niños (de 5 a 11 años de edad) están disponibles solamente para quienes recibieron la vacuna Pfizer-BioNTech y completaron la serie inicial de dosis hace al menos 5 meses.",
    },
    secondBoosterDateWarning: {
        en: "COVID-19 Vaccine second booster shots are available for vaccine recipients who completed their booster dose at least 4 months ago. Click 'Next' to proceed.",
        es: "Están disponibles las segundas dosis de refuerzo de las vacunas contra el COVID-19 para quienes completaron su vacunación con el primer refuerzo hace al menos 4 meses. Haga clic en 'Siguiente' para proceder."
    },
    boosterTooSoonWarning: {
        en:
            "Please note: COVID-19 vaccine booster shots are available for recipients who completed their initial series at least 2 months (60 days) earlier for Pfizer-BioNTech, Moderna, Janssen or Novavax COVID-19 vaccines. Click 'Submit' to proceed.",
        es:
            "Tenga en cuenta lo siguiente: las dosis de refuerzo contra el COVID-19 están disponibles para quienes completaron la serie inicial de vacunas contra el COVID-19 hace al menos 2 meses (60 días), si recibieron la vacuna Pfizer-BioNTech, Moderna, Janssen (Johnson & Johnson) y Novavax. Haga clic en 'Enviar' para proceder.",
    },
    scheduleTesting: {
        en: "Schedule your COVID-19 testing appointment",
        es: "Programe su cita para hacerse la prueba de COVID-19",
    },
    scheduleTestingNote: {
        en: "Schedule your **COVID-19 testing appointment** by finding a clinic and a time slot that works for you.",
        es:
            "Encuentre un centro médico y un horario conveniente para programar su cita para hacerse la prueba de COVID-19.",
    },
    testingInformational: {
        en:
            "Find out more about [Covid-19 testing](https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html).",
        es:
            "[Haga clic aquí](https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html) para obtener más información sobre las pruebas de COVID-19.",
    },
    modernaThirdDoseWarning: {
        en: "Third dose of Peds Moderna COVID-19 vaccine is recommended for recipients who are moderately or severely immunocompromised at least 4 weeks after second dose.",
        es: "Se recomienda administrar una tercera dosis de la vacuna contra el COVID-19 Moderna pediátrica a las personas que van a recibir la vacuna y están moderada o gravemente inmunodeprimidas al menos 4 semanas después de la segunda dosis."
    },
    pedsBivalentWarning: {
        en: 'Please note: Children 6 months through 4 years of age who have already completed their three-dose primary series with the original (monovalent) Pfizer-BioNTech COVID-19 Vaccine will not be eligible for a booster dose of an updated bivalent vaccine at this time.',
        es: 'Tenga en cuenta que: Los niños de seis meses a 4 años de edad que ya completaron su serie primaria de tres dosis con la vacuna contra el COVID-19 original (monovalente) de Pfizer-BioNTech no serán elegibles para recibir una dosis de refuerzo con una vacuna actualizada bivalente en estos momentos.'
    },
    vaccineICNameError: {
        en: "Please select a reason for scheduling an appointment.",
        es: "Seleccione la razón de su cita."
    },
    mmrRecommendations: {
        en: "To view CDC's recommendations for Measles, Mumps, Rubella (MMR) vaccination of special populations, [Click Here](https://www.cdc.gov/vaccines/vpd/mmr/hcp/recommendations.html).",
        es: "Para ver las recomendaciones de los CDC para la vacunación de las poblaciones especiales contra el sarampión, las paperas y la rubéola (MMR), [haga clic aquí](https://www.cdc.gov/vaccines/vpd/mmr/hcp/recommendations.html)."
    },
    mmrLaterTime: {
        en: "Based on the information provided, the recipient is not eligible to receive this vaccine. Please schedule an appointment at a later time.",
        es: "Con base en la información proporcionada, la persona que se va a vacunar no es elegible para recibir esta vacuna. Programe una cita más adelante."
    },
    mmrDateRecived: {
        en: "When did you receive the latest dose of MMR vaccine?",
        es: "¿Cuándo recibió la última dosis de la vacuna MMR?"
    },
    polioRecommendation: {
        en: "CDC recommends that children get four doses of [polio vaccine](https://www.cdc.gov/vaccines/vpd/polio/public/index.html). They should get one dose at each of the following ages: 2 months old, 4 months old, 6 through 18 months old, and 4 through 6 years old.",
        es: "Los CDC recomiendan que los niños reciban cuatro dosis de [la vacuna contra la poliomielitis](https://www.cdc.gov/vaccines/vpd/polio/public/index.html). Deben recibir una dosis a cada una de las siguientes edades: 2 meses, 4 meses, entre los 6 y los 18 meses, y entre los 4 y los 6 años de edad."
    },
    polioAtRiskWarning: {
        en: "To view CDC's recommendations for Polio vaccination of special populations, [click here](https://www.cdc.gov/vaccines/vpd/polio/hcp/recommendations.html#:~:text=Unvaccinated%20adults%20who%20are%20at,months%20after%20the%20second%20dose|#:~:text=Unvaccinated%20adults%20who%20are%20at,months%20after%20the%20second%20dose).",
        es: "Para ver las recomendaciones de los CDC sobre la vacunación contra la poliomielitis en poblaciones especiales, [haga clic aquí](https://www.cdc.gov/vaccines/vpd/polio/hcp/recommendations.html#:~:text=Unvaccinated%20adults%20who%20are%20at,months%20after%20the%20second%20dose\|#:~:text=Unvaccinated%20adults%20who%20are%20at,months%20after%20the%20second%20dose)."
    },
    polioDateRecived: {
        en: "When did you receive the latest dose of Polio vaccine?",
        es: "¿Cuándo recibió la dosis más reciente de la vacuna contra la poliomielitis?"
    },
    childrenVaccines: {
        en: "To learn more about CDC's vaccine recommendations for children, adolescents and adults, click the links below",
        es: "Para saber más acerca de las recomendaciones de los CDC sobre vacunas para niños, adolescentes y adultos, haga clic en los enlaces más abajo",
    },
    childrenVaccines18: {
        en: "[Recommendations for Ages 18 Years or Younger](https://www.cdc.gov/vaccines/schedules/hcp/imz/child-adolescent.html).",
        es: "[Recomendaciones para personas de 18 años o menos](https://www.cdc.gov/vaccines/schedules/hcp/imz/child-adolescent.html)."
    },
    childrenVaccines19: {
        en: "[Recommendations for Ages 19 Years or Older](https://www.cdc.gov/vaccines/schedules/hcp/imz/adult.html).",
        es: "[Recomendaciones para personas de 19 años o más](https://www.cdc.gov/vaccines/schedules/hcp/imz/adult.html).",
    },
    bivalentUpdate: {
        en: "Individuals 65 years of age and older who have received one dose of a bivalent COVID-19 vaccine may be eligible to receive a Pfizer-BioNTech COVID-19 Vaccine, Bivalent or a Moderna COVID-19 Vaccine, Bivalent at least 4 months after the dose of the previous bivalent COVID-19 vaccine",
        es: "Las personas de 65 años o más que han recibido una dosis de la vacuna bivalente contra el COVID-19 podrían ser elegibles para recibir una vacuna bivalente contra el COVID-19 de Pfizer-BioNTech, o una vacuna bivalente contra el COVID-19 de Moderna, al menos 4 meses después de recibir la dosis anterior de la vacuna bivalente contra el COVID-19."

    },
    requiredNote: {
        en: "Please note all questions that have  \" * \" are all mandatory fields",
        es: "El asterisco ( * ) indica que debe llenar esta casilla."
    },
    mpoxNote: {
        en: "According to CDC recommendations, only JYNNEOS (also known as Imvamune or Imvanex) and ACAM2000 are available for preventing Monkeypox infection. Please refer to the CDC guidelines prior to scheduling an appointment. [Click here to find out more](https://www.cdc.gov/poxvirus/monkeypox/considerations-for-monkeypox-vaccination.html).",
        es: "According to CDC recommendations, only JYNNEOS (also known as Imvamune or Imvanex) and ACAM2000 are available for preventing Monkeypox infection. Please refer to the CDC guidelines prior to scheduling an appointment. [Click here to find out more](https://www.cdc.gov/poxvirus/monkeypox/considerations-for-monkeypox-vaccination.html)."
    },
    monovalentNote:  {
        en: "The Pfizer and Moderna monovalent (original) mRNA COVID-19 vaccines will no longer be recommended for use in the United States. [Learn more](https://www.cdc.gov/media/releases/2023/s0419-covid-vaccines.html).",
        es: "Las vacunas de ARNm contra el COVID-19 monovalentes (originales) Pfizer y Moderna ya no se recomendarán para su uso en los Estados Unidos. [Infórmese más](https://www.cdc.gov/spanish/mediosdecomunicacion/comunicados/d_vacunas-covid_041923.html).",
    },
    acamError: {
        en: "The ACAM2000 Vaccine is a one-dose vaccine. If it has already been received, then the vaccination is completed.",
        es: "La ACAM2000 Vaccine es una vacuna de una sola dosis. Si ya se ha recibido, entonces la vacunación está completa."
    },
};

export function GetLabelForLanguage(id: LabelId, lang: LanguageId = "en"): string {
    return Labels[id][lang];
}
